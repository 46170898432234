
const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;

const FetchQueues = async (business_id, token) => {
  const response = await fetch(`${BASE_URL}/get_number_of_queues?business_id=${business_id}&auth_token=${token}`, {credentials: 'include'});
  if (!response.ok) {
    throw new Error('Failed to fetch queues');
  }
  const result = await response.json();
  return result.data;
};

const Logout = async() => {
  const response = await fetch(`${BASE_URL}/logout`, {credentials: 'include'});
}


const CustomerInfo = async(params) => {
  let url = `${BASE_URL}/customer_info?start_time=${params.start_time}&end_time=${params.end_time}`;
  const response = await fetch(url, {credentials: 'include'});
  
  if (!response.ok) {
    throw new Error('Failed to fetch users');
  }
  const result = await response.json();
  return result.data;
};


const UpdateQueueNumber = async (token, queue_id, estimated_wait_time) => {
  const response = await fetch(`${BASE_URL}/update_queue_estimated_time?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      queue_id,
      estimated_wait_time
    })
  });

  if (!response.ok) {
    throw new Error('Failed to mark user as entered');
  }

  const result = await response.json();
  return result;
};


const add_note = async (token, id, text) => {
  const response = await fetch(`${BASE_URL}/create_or_update_notes?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      text: text,
      entity_id: id,
      entity_type: "business_customer_mappings"

    })
  });

  if (!response.ok) {
    throw new Error('Failed to mark user as entered');
  }

  const result = await response.json();
  return result;
};




const UpdateQueueStatus = async (token, queue_id, status) => {
  const response = await fetch(`${BASE_URL}/create_or_update_business_queue?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      id: queue_id,
      mark_as_active: status
    })
  });

  if (!response.ok) {
    throw new Error('Cannot change queue status');
  }

  const result = await response.json();
  return result;
};


const makeCall = async (business_customer_mapping_id, token) => {
  
    const response = await fetch(`${BASE_URL}/mark_table_ready?mapping_id=${business_customer_mapping_id}&auth_token=${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    console.log(response);
    if (!response.ok) {
      throw new Error('Cannot Call');
    }
    const result = await response.json();
    return result.data;
};



const FetchUsers = async (business_id, business_user_id, queue, token) => {
  let url = `${BASE_URL}/list_items_in_queue?business_id=${business_id}&business_user_id=${business_user_id}&auth_token=${token}`;
  if (queue !== 'All') {
    url += `&queue_id=${queue}`;
  }
  const response = await fetch(url, {credentials: 'include'});
  if (!response.ok) {
    throw new Error('Failed to fetch users');
  }
  const result = await response.json();
  return result.data;
};


const GetBusinessInfo = async(business_id) => {
  let url = `${BASE_URL}/business_info?business_id=${business_id}`
  const response = await fetch(url, {credentials: 'include'});
  
  if (!response.ok) {
    throw new Error('Failed to fetch users');
  }
  const result = await response.json();
  return result.data;
};

const AddUser = async (user, token) => {
  const response = await fetch(`${BASE_URL}/add_or_update_user?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
    credentials: 'include'
  });
  if (!response.ok) {
    throw new Error('Failed to add user');
  }
  const result = await response.json();
  return result;
};

const UpdateBusinessInfo = async (details, business_id, token) => {
  const request_body = {
    'id': business_id,
    'business_metadata': details
  }
  const response = await fetch(`${BASE_URL}/create_or_update?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request_body),
    credentials: 'include'
  });
  if (!response.ok) {
    throw new Error('Failed to add user');
  }
  const result = await response.json();
  return result;
};

const EditUser = async (user, token) => {
  const response = await fetch(`${BASE_URL}/add_or_update_user?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
    credentials: 'include'
  });
  if (!response.ok) {
    throw new Error('Failed to edit user');
  }
  const result = await response.json();
  return result;
};

const DeleteUser = async (business_customer_mapping_id, removed_reason, business_id) => {
  const response = await fetch(`${BASE_URL}/mark_customer_delete?business_id=${business_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      business_customer_mapping_id,
      removed_reason
    })
  });

  if (!response.ok) {
    throw new Error('Failed to delete user');
  }
  const result = await response.json();
  return result;
};

const MarkUserAsEntered = async (business_customer_mapping_id, token, table_no, entry_time) => {
  const response = await fetch(`${BASE_URL}/mark_customer_entered?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      business_customer_mapping_id,
      table_no,
      entry_time
    })
  });

  if (!response.ok) {
    throw new Error('Failed to mark user as entered');
  }

  const result = await response.json();
  return result;
};



const GetQRCode = async(token) => {
  const response = await fetch(`${BASE_URL}/generate_qr?auth_token=${token}`)
  if (!response.ok) {
    throw new Error('Failed to fetch QR code');
  }
  const blob = await response.blob();
  const qrCodeUrl = URL.createObjectURL(blob);
  return { qrCodeUrl };
};
export default {
  FetchQueues,
  GetBusinessInfo,
  FetchUsers,
  AddUser,
  EditUser,
  DeleteUser,
  MarkUserAsEntered,
  GetQRCode,
  CustomerInfo,
  UpdateQueueNumber,
  Logout,
  UpdateQueueStatus,
  add_note,
  makeCall,
  UpdateBusinessInfo
};